import axios from 'axios';
import config from 'config';
import useSWR from 'swr';
import { useSelector } from 'react-redux';
import { ReduxState } from 'ducks';

import { toProductSummaryShape, ProductSummaryShape } from 'lib/util/productSummaryShape';
import { ProductSummary } from 'models/product';

export const useFrequentlyViewedProducts = (
  ownProductId: string | undefined,
  apiKey: string,
  locale: string
): ProductSummaryShape[] => {
  const fvp = useSelector(
    (state: ReduxState) => state.server.settings.all.frequently_viewed_products
  );

  const fetcher = async (
    url: string,
    apiKey: string,
    locale: string
  ): Promise<ProductSummary[]> => {
    const response = await axios
      .get(url, {
        headers: { 'x-api-key': apiKey, 'accept-language': locale },
      })
      .then((response) => response.data.products);
    return response;
  };

  const products = useSWR<ProductSummary[]>([`${config.apiUrl}/products`, apiKey, locale], fetcher);

  const rankingList: ProductSummaryShape[] | undefined = fvp
    ?.map((elm) => products.data?.find((p: ProductSummary) => p.id === elm))
    ?.filter(
      (product) =>
        Boolean(product) &&
        product?.id != ownProductId &&
        product?.booking_widget_settings?.is_visible &&
        !product?.booking_widget_settings?.is_view_only
    )
    ?.slice(0, 5)
    ?.map((product) => {
      return product ? toProductSummaryShape(product) : undefined;
    })
    ?.filter((product): product is Exclude<typeof product, undefined> => product !== undefined);

  return rankingList ?? ([] as ProductSummaryShape[]);
};
