import * as React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Link from 'next/link';

import { ReduxState } from 'ducks';

import { ApiKeyContext } from 'contexts/ApiKeyContext';
import { useFrequentlyViewedProducts } from 'lib/util/useFrequentlyViewedProducts';
import { CardSlider } from 'components/TopPageCustomSections/CardSlider/CardSlider';
import { ProductSummaryCard } from 'components/ProductSummaryCard/ProductSummaryCard';
import { CardSlider as PMPCardSlider } from 'components/PrivateMarketplace/CardSlider/CardSlider';
import { ProductCard } from 'components/PrivateMarketplace/ProductCard/ProductCard';
import { appendQueryString } from 'lib/util/appendQueryString';
import { selectIsPMP } from 'lib/util/privateMarketplace';
import { useQueryString } from 'hooks/useQueryString';

import clsx from 'clsx';
import { BookingWidgetThemeContext } from 'contexts/BookingWidgetThemeContext';
import { getThemeCssOverride } from 'components/Themes';

export const FrequentlyViewedProducts = () => {
  const { t, i18n } = useTranslation();
  const { apiKey } = React.useContext(ApiKeyContext);

  const product = useSelector((state: ReduxState) => state.server.product.product);
  const isPMP = useSelector(selectIsPMP);

  const isHide = useSelector(
    (state: ReduxState) =>
      state.server.settings.all.booking_widget_design_params?.hide_frequently_viewed_products
  );

  const frequentlyViewedList = useFrequentlyViewedProducts(product?.id, apiKey, i18n.language);

  const queryString = useQueryString();

  const bookingWidgetTheme = React.useContext(BookingWidgetThemeContext);

  if (isHide || frequentlyViewedList.length === 0) {
    return null;
  }

  return isPMP ? (
    <div className={clsx('base-related', getThemeCssOverride(bookingWidgetTheme, 'base-related'))}>
      <h3 className="base-related__headline">{t('Frequently viewed')}</h3>
      <PMPCardSlider
        centerNarrowContent={true}
        data={frequentlyViewedList}
        renderElement={(product) => (
          <Link href={appendQueryString(`/top/products/${product.id}`, queryString)}>
            <ProductCard displayLabelTypes={['FREQUENTLY_VIEWED']} product={product} />
          </Link>
        )}
      />
    </div>
  ) : (
    <div className={clsx('base-related', getThemeCssOverride(bookingWidgetTheme, 'base-related'))}>
      <h3 className="base-related__headline">{t('Frequently viewed')}</h3>
      <CardSlider
        centerNarrowContent={true}
        data={frequentlyViewedList}
        renderElement={(product) =>
          product ? <ProductSummaryCard product={product} orientation="VERTICAL" /> : null
        }
      />
    </div>
  );
};
